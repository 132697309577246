@import './app/@theming/themes/default.less';
@import './app/@theming/global/components.less';
@import './primeng.min.css';
@import './lara-light-blue.theme.css';
@import './primeflex.min.css';

// Theme LESS Code & User LESS Code
@import "./assets/libraries/primeicons/primeicons.css";

html { 
    font-size: 14px; 
}

.p-input-icon-right > i:nth-last-of-type(2) {
  right: 2rem;
  color: #6c757d;
}

.p-input-icon-right > i:nth-last-of-type(3) {
  right: 3.5rem;
}

.list-status-default table.dataTable,
.list-status-default table.dataTable.display {
    text-align: left;
}

.master-page-layout {
  display: block;
  padding: 0px;
  margin: 0px 2rem;
}

&.required {
    position: relative;
    
    &::after {
      content: ".";
      position: absolute;
      top: -1.25rem;
      right: 0.25rem;
      line-height: 0rem;
      font-size: 3.5rem;
      color: red;
    }
    
    &.zapp-textarea::after {
        top: -0.75rem;
    }
    
    &.zapp-richtextbox::after {
        top: -0.5rem;
    }
}

.master-page-footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 25px;
    line-height: 25px;
    z-index: 10;
    background-color: @containers-color;//#fafbfc;
}

.p-container {
    padding: 1.5rem 3rem 3rem 3rem;
    
    &.fixed-width {
        width: 30rem;
    }
}

.with-right-element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.p-panel-header {
    .push-right {
        margin-left: auto;
    }
}

/* SideBar Layout */
@sidebar-width: 235px;
@collapsed-sidebar-width: 63px;

body:not(.sidebar-menu) {
    div.master-container {
        > div {
            width: 100%;    
        }
    }
}

body.sidebar-menu {
    div.master-container {
        flex-flow: row!important;
        
        > div {
            height: calc(100vh - 25px);
            
            &.menu-container {
                position: fixed;
                z-index: 50;
            }
            
            &.body-container {
                flex-grow: 1;
                margin-left: @sidebar-width;
            }
        }
    }
    
    &.sidebar-menu-collapsed {
        .body-container {
            margin-left: @collapsed-sidebar-width!important;
        }
    }
}
/* SideBar Layout End */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
 .container {
     width: 540px;
 }
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
 .container {
     width: 720px;
 }
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
 .container {
     width: 960px;
 }
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    .container {
     width: 1140px;
 }
}




